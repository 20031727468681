import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import {AppContext} from "../context/appContext"
import {LoginBox} from "../components/LoginComponents";
import SignUpBox from "../components/LoginComponents/SignUpBox";
import ImageGallery from "../components/ImageGallery";
import { motion } from "framer-motion"
import {useUser} from "../context/userContext";

export default function Login() {

  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [isFocusOnLogin, setFocusOnLogin] = useState(true);   // focus animation

  const {hasLoggedIn, setHasLoggedIn} = useContext(AppContext);
  const {login, err} = useUser();
  const navigate = useNavigate();

  async function handleLogin(){
    await login(username, password);
  }

  const handleTextFieldChange = (event) => {
  let targetId = event.target.id;
  if (targetId === "username-text-field") {
    setUsername(event.target.value);
  } else if (targetId === "password-text-field") {
    setPassword(event.target.value);
  } else {
    console.error("Invalid handling of textfield");
  }
};

  console.log("isFocusOnLogin: ", isFocusOnLogin);

  return (
    <div className="flex flex-col px-16 justify-center h-screen">
    <div className="flex flex-row justify-center gap-4">
      {!hasLoggedIn
       ?
        (isFocusOnLogin
          ?
          <motion.div
               initial={{ opacity: 0, y: 100 }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ duration: 0.5 }}
               className={"flex flex-row rounded-lg shadow-lg"}>
            <LoginBox setFocusOnLogin={setFocusOnLogin} handleTextFieldChange={handleTextFieldChange} handleLogin={handleLogin} />
            <ImageGallery />
          </motion.div>
          :
          <SignUpBox setFocusOnLogin={setFocusOnLogin}/>
        )
      :
      (
        <div>
          <h1>Successfully logged in, redirecting...</h1>
          <p>
            If redirection not working, click this link
            <Link href="/form" className="btn btn-ghost normal-case text-lg">
              My Account
            </Link>
          </p>
        </div>
      )}
    </div>
    </div>
  );
}
