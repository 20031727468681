import React, {useState, createContext, useContext} from 'react';

export const LANG = {
    EN: "en",
    CN: "cn",
}
export const AppContext = createContext({});

function AppProvider({children}) {
  const [lang, setLang] = useState(LANG.EN);

  return (
      <AppContext.Provider value={{lang, setLang}}>
        {children}
      </AppContext.Provider>
  );
}

export const useApp = () => useContext(AppContext);

export default AppProvider;