import {sample1_stanford_essay, sample1_stanford_essay_suggestions} from "./sample1_stanford_essay";
import {sample2_gtech_essay, sample2_gtech_essay_suggestions} from "./sample2_gtech_essay";

function Variation(id, underDocId, version, content, suggestions) {
    return{
        id: id,
        underDocId: underDocId,
        version: version,
        content: content,
        suggestions: suggestions,
        isShow: true, // show or hide this variation, default is true
    }
}

function Doc(id, name,school, location, type, variations) {
    return{
        id: id,
        name:name,
        school: school,
        location: location,
        type: type,
        variations: variations,
        isShow: true, // show or hide this doc, default is true
    }
}

const doc0Variation0 = Variation('doc-00-0','doc-00', 1, sample1_stanford_essay, sample1_stanford_essay_suggestions);
const doc0Variation1 = Variation('doc-00-1','doc-00', 2, sample1_stanford_essay, sample1_stanford_essay_suggestions);
const doc0 = Doc('doc-00','Stanford Essay', 'Stanford', 'US', 'Essay', [doc0Variation0, doc0Variation1]);

const doc1Variation0 = Variation('doc-01-0','doc-01', 1, sample2_gtech_essay, sample2_gtech_essay_suggestions);
const doc1Variation1 = Variation('doc-01-1','doc-01', 2, sample2_gtech_essay, sample2_gtech_essay_suggestions);
const doc1Variation2 = Variation('doc-01-2','doc-01', 3, sample2_gtech_essay, sample2_gtech_essay_suggestions);
const doc1Variation3 = Variation('doc-01-3','doc-01', 4, sample2_gtech_essay, sample2_gtech_essay_suggestions);
const doc1 = Doc('doc-01', 'GTech Essay','GTech', 'US', 'Essay', [doc1Variation0, doc1Variation1, doc1Variation2,doc1Variation3]);

export const docs = [doc0, doc1];

// api? 1-get all docs
// suggestions for each variation