import {useContext, useEffect, useState} from "react";
import {ProfileContext} from "../../../context/profileContext";
import ProfileMatchingCard from "./ProfileMatchingCard";
import {useParams} from "react-router-dom";

function ProfileMatching(){
    const singlePartId = useParams().singlePartId;
    const {getProfileMatching, setShowProfileMatching, updateComponentIdByParams} = useContext(ProfileContext)
    const [isLoaded, setIsLoaded] = useState(false)
    const [profileMatchings, setProfileMatchings] = useState([])

    useEffect(() => {
        updateComponentIdByParams(singlePartId);
    }, []);

    useEffect(() => {
        const fetchProfileMatching = async () => {
            const result = await getProfileMatching();
            setProfileMatchings(result);
            setShowProfileMatching(true);
            setIsLoaded(true);
        };

        fetchProfileMatching();
    }, [getProfileMatching]);

    if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <ProfileMatchingCard profileMatchings={profileMatchings}/>
        );
    }
}

export default ProfileMatching;