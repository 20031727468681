import {useParams} from 'react-router-dom';
import {Activities, Awards, BasicInfo, TestScore} from "../components/ProfileComponents";
import React, {useEffect} from "react";
import ProfileNextButton from "../components/ProfileComponents/ProfileNextButton";
import ProfilePreviousButton from "../components/ProfileComponents/ProfilePreviousButton";
import InfoSummary from "../components/ProfileComponents/userInfo/InfoSummary";
import SaveAsDraftBtn from "../components/ProfileComponents/SaveAsDraftBtn";
import ProfileMatching from "../components/ProfileComponents/profileMatching/ProfileMatching";
import {useProfileContext} from "../context/profileContext";

const SingleProduct = () => {
    const singlePartId = useParams().singlePartId;
    const {getUserInfoFromCloud} = useProfileContext();
    // console.log("singlePartId", singlePartId);

    useEffect(() => {
        // getUserInfoFromCloud();
    },[])

    return (
        <div className={"flex flex-col h-[70vh]"}>
            <div>
                {singlePartId === "basic-info" && <BasicInfo/>}
                {singlePartId === "test-score" && <TestScore/>}
                {singlePartId === "awards" && <Awards/>}
                {singlePartId === "activities" && <Activities/>}
                {singlePartId === "info-summary" && <InfoSummary/>}
                {singlePartId === "profile-matching" && <ProfileMatching />}
            </div>
            <div className={"flex-grow"} />

        </div>
    );
};

export default SingleProduct;
