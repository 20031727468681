import React, {useEffect} from 'react';
import { useProfileContext } from "../../../context/profileContext";
import {AiFillAlipayCircle, AiOutlineCloudUpload} from "react-icons/ai";
import {useNavigate, useParams} from "react-router-dom";

const InfoSummary = () => {
    const singlePartId = useParams().singlePartId;
    const navigate = useNavigate()
    const { userInfo, updateComponentIdByParams, saveUserInfoToCloud } = useProfileContext();

    function handleSubmit() {
        navigate("/profile/profile-matching")
    }

    useEffect(() => {
        updateComponentIdByParams(singlePartId);
        saveUserInfoToCloud();
        console.log('userInfo: ', userInfo)
    },[])

    return (
        <div>
            <h2>Summary</h2>
            <section className={'h-full grid grid-cols-[2fr_2fr]'}>
                <div className={'pl-3 border-r border-r-sky-50'}>
                    <h4>Basic Info</h4>
                    <p>First Name: {userInfo.basicInfo.firstName}&nbsp; Last Name: {userInfo.lastName}</p>
                    <p>BirthDate: {userInfo.basicInfo.birthDate}&nbsp; Nation: {userInfo.nation}&nbsp; School: {userInfo.school}</p>
                    <h4>Exam:</h4>
                    <pre>
                        {/*{JSON.stringify(userInfo.testScore, null, 2)}*/}
                        {Array.isArray(userInfo.testScore) && userInfo.testScore.map((score, index) => {
                            return (
                                <div key={index} className={'pt-1'}>
                                    <p>{score.testType}: {score.testScore}</p>
                                    <p>section scores: &nbsp;
                                        {score?.listening && <span className={'pl-1'}>listening: {score?.listening}</span>}
                                        {score?.reading && <span className={'pl-1'}>reading: {score?.reading}</span>}
                                        {score?.writing && <span className={'pl-1'}>writing: {score?.writing}</span>}
                                        {score?.speaking && <span className={'pl-1'}>speaking: {score?.speaking}</span>}
                                    </p>
                                </div>
                            )})
                        }
                    </pre>
                    <h4>Awards:</h4>
                    <pre>
                        {/*{JSON.stringify(userInfo.awards, null, 2)}*/}
                        {Array.isArray(userInfo.awards) && userInfo.awards.map((award, index) => {
                            if(award.awardName === null || award.awardName === '') return <p>N/A</p>;
                            return (
                                <div key={index}>
                                    <p>name: {award?.awardName} grade: {award?.awardGrade}</p>
                                    <p>type: {award?.awardType}</p>
                                    <p>subject: {award?.awardSubject}</p>
                                    <p>level: {award?.awardLevel}</p>
                                </div>
                            )})}
                    </pre>
                    <h4>Activities:</h4>
                    <pre>
                        {/*{JSON.stringify(userInfo.activities, null, 2)}*/}
                        {Array.isArray(userInfo.activities) && userInfo.activities.map((activity, index) => {
                            if(activity.activityName === null || activity.activityName === '') return <p>N/A</p>;
                            return (
                                <div key={index}>
                                    <p>name: {activity?.activityName}</p>
                                    <p>type: {activity?.activityType}</p>
                                    <p>level: {activity?.activityLevel}</p>
                                </div>
                            )})
                        }
                    </pre>
                </div>
                <div className={'w-full h-full flex flex-row justify-center items-center text-slate-500'}>
                    <button onClick={handleSubmit} ><AiOutlineCloudUpload color={'#5B7C99'} size={72}/>Submit</button>
                </div>
            </section>
        </div>
    );
}

export default InfoSummary;
