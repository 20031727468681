export const sample1_stanford_essay = "Essay Question:\n" +
    "\n" +
    "What do you think is meaningful? (Stanford)\n" +
    "\n" +
    "Essay:\n" +
    "\n" +
    "In Danzhai, an impoverished village barricaded by layers of mountains and isolated from development, I saw left-behind children’s eyes dimmed with helplessness and insecurity, the elderly subdued to poverty, and the magnificent traditional Batik Art losing its glamor.\n" +
    "\n" +
    "Thus, I started Batik Legend Charity Club, establishing the slogan “Commerce, Connection, Compassion” to organize charity speeches and sales on Danzhai’s traditional artwork. Two workshops were established in Danzhai, attracting eight adults back to the village for work and bringing their children parental company. I saw children’s eyes lighted by excitement.\n" +
    "\n" +
    "In the next year, I collaborated with other charity organizations for the Batik art sale and established a book corner in Danzhai. I saw children’s eyes brightened by curiosity. In year three, I established branches in Qingdao and Chengdu, bringing more people to join the cause and raising $5000. I saw the Batik art shining on the display. This year, four more village workshops were built, and we now have forty young people returning to the village working on Batik art. Each time I visited the village, I saw new eyes, glowing with passion, vitality, and gratefulness.\n" +
    "\n" +
    "This summer I visited the county again. Seeing a disabled 78-year-old woman working carefully at her table, drawing beautiful pictures, I knew that the community has been rebuilt, and that compassion has been connected through commerce.\n" +
    "\n" +
    "To connect with compassion, bring hope with effort, invigorate communities with commerce, and preserve culture with development, that’s meaningful to me."

export const sample1_stanford_essay_suggestions = "Perspective 1: Harvard Admissions Officer Perspective\n" +
    "\n" +
    "Strengths\n" +
    "\n" +
    "- Line 4: Be Authentic - The narrative speaks genuinely about the author's experiences in Danzhai, giving the reader an authentic understanding of the author's commitment to their cause. [Score: 9/10]\n" +
    "- Line 5: Show Don’t Tell - The applicant successfully uses descriptive storytelling (\"I saw children’s eyes lighted by excitement.\") to convey their actions' impact instead of just listing their achievements. [Score: 8/10]\n" +
    "- Line 15: End Your Essay with a \"Kicker\" - The concluding sentence effectively sums up the writer's understanding of what is meaningful. It's strong and memorable. [Score: 8.5/10]\n" +
    "\n" +
    "Weaknesses\n" +
    "\n" +
    "- Line 2: Grab the Reader from the Start - The opening sentence is too broad, which doesn't immediately catch the reader's attention. [Score: 6/10]\n" +
    "- Lines 5-14: Write With the Reader in Mind - The ideas seem to be slightly disorganized and jump from one project to another, which can confuse the reader. [Score: 5/10]\n" +
    "- Line 15: Pay Attention to Form - The conclusion might benefit from a more personal, individual understanding rather than broad, abstract ideas. [Score: 6.5/10]\n" +
    "\n" +
    "Average Score: 7.2/10\n" +
    "\n" +
    "General Comment: The essay provides an authentic account of the applicant's endeavors and impacts. It could be improved by focusing on creating a more compelling opening and organizing the narrative in a more reader-friendly way."