import React from "react";

export default function DashLine() {
    return (
        <div className={"relative"}>
            <div className={"absolute w-1 h-1 rounded-full bg-gray-300 left-[-2px] top-[-2px]"} />
            <hr className={"h-[2px] w-12 bg-gray-300"} />
            <div className={"absolute w-1 h-1 rounded-full bg-gray-300 right-[-2px] top-[-2px]"} />
        </div>
    )
}