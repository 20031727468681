import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {motion} from "framer-motion";
import DashLine from "./DocsComponents/DashLine";

export default function DocCard(props) {
    const {doc} = props;
    const variations = doc.variations;
    const navigate = useNavigate();
    const [isFolded, setIsFolded] = useState(true);
    const [showAllVariations, setShowAllVariations] = useState(false);

    // a list of objects, each object is a variation => an object with the largest version number is the latest variation
    const latestVariation = variations.reduce((prev, current) => (prev.version > current.version) ? prev : current, variations[0]);
    const pastVariations = variations
        .filter(variation => variation.version < latestVariation.version)
        .sort((a, b) => b.version - a.version)
    ;

    // if there are more than 2 past variations, only show 2
    // otherwise, no difference
    const limitedPastVariations = showAllVariations ? pastVariations : pastVariations.slice(0, 2);

    function handleDocClick(docVariationId) {
        navigate(`/docs/${docVariationId}`);
    }

    return(
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{duration: 0.5}}
            className={"relative flex gap-0"}>
            {/* main variation card */}
            <div className={"py-4 pl-4 pr-6 border border-gray-300"}>
                <img
                    className={"cursor-pointer"}
                    onClick={() => handleDocClick(latestVariation.id)}
                    src={'https://google.oit.ncsu.edu/wp-content/uploads/sites/6/2021/01/Google_Docs.max-2800x2800-1.png'}
                    width={100}
                    alt="doc image"
                />
                <h3>{doc.name}</h3>
                <p>School: {doc.school}</p>
                <p>Location: {doc.location}</p>
                <p>Type: {doc.type}</p>
                <p>version: {latestVariation.version}</p>
            </div>
            {/* unfold btn */}
            {isFolded && latestVariation.version !== 1
                && <motion.button
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 0.5}}
                    className={"absolute w-9 h-9 right-[-18px] top-1/2 translate-y-[-18px] bg-white border border-gray-200 rounded-full"}
                    onClick={()=>setIsFolded(false)}> &gt; </motion.button>}
            {/* unfold condition */}
            {!isFolded && !!pastVariations &&
                <div className={"flex gap-0 "}>
                    {/*variation cards.*/}
                    {limitedPastVariations.map((variation, index) => {
                        return (
                            <motion.div
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                transition={{duration: 0.5}}
                                className={"flex flex-row gap-0 items-center"}>
                                {/* dash line to visually connect 2 cards */}
                                <DashLine />
                                {/* card */}
                                <div
                                    className={"py-4 pl-4 pr-6 border border-gray-300"}
                                    key={`variation-${index}-${variation.version}`}>
                                    {/* the doc name, school location type will be same, only versions are different */}
                                    <img
                                        className={"cursor-pointer"}
                                        onClick={() => handleDocClick(variation.id)}
                                        src={'https://google.oit.ncsu.edu/wp-content/uploads/sites/6/2021/01/Google_Docs.max-2800x2800-1.png'}
                                        width={100}
                                        alt="doc image"
                                    />
                                    <h3>{doc.name}</h3>
                                    <p>School: {doc.school}</p>
                                    <p>Location: {doc.location}</p>
                                    <p>Type: {doc.type}</p>
                                    <p>version: {variation.version}</p>
                                </div>
                            </motion.div>
                        )
                    })}
                    {/* function card*/}
                    <div className={"relative flex flex-row items-stretch"}>
                        <div className={"flex items-center"}>
                            <DashLine />
                        </div>
                        <div className={"w-36 border border-gray-300 flex flex-col justify-center items-center gap-3"}>
                            <button>Edit Chain</button>

                            {/* more button */}
                            {pastVariations.length > 2 &&
                                <button onClick={() => setShowAllVariations(prevState => !prevState)}>
                                    {showAllVariations ? 'Show Less' : 'More'}
                                </button>
                            }

                            <button onClick={()=>setIsFolded(true)}>Close</button>

                            {/* fold btn */}
                            {!isFolded &&
                                <motion.button
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    transition={{duration: 0.5}}
                                    className={"absolute w-9 h-9 right-[-18px] top-1/2 translate-y-[-18px] bg-white border border-gray-300 rounded-full"}
                                    onClick={()=>setIsFolded(true)}> &lt; </motion.button>}
                        </div>
                    </div>
                </div>}
        </motion.div>
    )
}