import React, {createContext, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {LOCALSTORAGE_KEY} from "../constants";
import format from '../Data/Profile-matching/format'
import format2 from '../Data/Profile-matching/format2'
import {defaultUserInfo, } from "../constants/userInfoInitialState";
import {WELIGHT_USER_TOKEN_STORAGE_NAME} from "./userContext";
import {toast, ToastContainer} from "react-toastify";

const storedUserInfo = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));

const initialUserInfo = {
    "basicInfo": {
        "firstName": " ",
        "lastName": " ",
        "gender": " ",
        "school": "TshingHua Univ",
        "courseSystem": "ca",
        "birthDate": "1999-02-12",
        "country": "CN",
        "gpa": "4.0",
        "location": "CN",
    },
    "currentEducation": {
        "schoolName": "TshingHua Univ",
        "location": "CN",
        "courseSystem": "a",
        "grade": "4.0",
        "language": "CHN",
        "intendedMajor": "computer science"
    },
    "testScore": [
        {
            "testType": "TOEFL",
            "testScore": "100",
            "listening": "25",
            "reading": "25",
            "writing": "25",
            "speaking": "25",
        }
    ],
    "awards": [
        {
            "awardName": "award",
            "awardGrade": "a",
            "awardType": "a",
            "awardSubject": "a",
            "awardLevel": 1
        }
    ],
    "activities": [
        {
            "activityType": "activity-a",
            "activityName": "name a",
            "activityDetail": "",
            "activityLevel": 1
        }
    ]
}
export const navLinks = [
    { id:1, title: 'Basic Info', path: '/profile/basic-info' }, // abs path
    { id:2, title: 'Test Score', path: '/profile/test-score' },
    { id:3, title: 'Awards', path: '/profile/awards' },
    { id:4, title: 'Activities', path: '/profile/activities' },
    { id:5, title: 'Summary', path: '/profile/info-summary' },
    { id:6, title: 'Profile-matching', path: '/profile/profile-matching'}
];

const nextProfileComponentPath = (currentProfileComponentPath) => {
    const index = navLinks.findIndex((link) => link.path === currentProfileComponentPath);
    if(index === -1){
        console.log("navlink index not found");
        return "";
    }else{
        console.log("navlink successfully found",index);
        return navLinks[(index+1) % navLinks.length].path;
    }
}

const previousProfileComponentPath = (currentProfileComponentPath) => {
    const index = navLinks.findIndex((link) => link.path === currentProfileComponentPath);
    if(index === -1){
        console.log("navlink index not found");
        return "";
    }else{
        console.log("navlink successfully found",index);
        return navLinks[(index-1) % navLinks.length].path;
    }
}

export const ProfileContext = createContext({});


function ProfileProvider({children}) {
    const [currentProfileComponentId, setCurrentProfileComponentId] = useState(-1);
    const [userInfo, setUserInfo] = useState(initialUserInfo);
    const [isRequiredFilled, setIsRequiredFilled] = useState(true);
    const [showSummary, setShowSummary] = useState(false); // show summary page after submitting
    const [showProfileMatching, setShowProfileMatching]=useState(false)

    const setLocalStorage = () => {
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(userInfo));
        // alert("Saved to local storage")
    }
    const getLocalStorage = () => {
        return JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));
    }

    async function getUserInfoFromCloud(){
        console.log("getting user info from cloud")
        const token = localStorage.getItem(WELIGHT_USER_TOKEN_STORAGE_NAME);
        console.log("token: ",token)
        if(!token){
            console.error("token not found for get userinfo from cloud");
            return;
        }
        let response;
        try{
             response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/profile/`, {
                method: 'GET',
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                }
            });
        }catch(err){
            console.log("get user info error: ",err)
        }

        if (!response.ok) {
            console.log('Failed to get state from database')
            console.error('Error status:', response.status, 'Status text:', response.statusText);
            // Handle error
            return;
        }

        const {data: fetchedUserInfo} = await response.json();
        console.log('Successfully get state from database', fetchedUserInfo)
        setUserInfo(fetchedUserInfo);
    }

    async function saveUserInfoToCloud(){
        toast.info('Saving to cloud...', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        const token = localStorage.getItem(WELIGHT_USER_TOKEN_STORAGE_NAME);
        if(!token){
            console.error("token not found");
            toast.error('Something wrong!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/profile/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify(userInfo),
        });
        if (!response.ok) {
            console.log('Failed to save state to database')
            setIsSavingState(false);
            // Handle error
            return;
        }
        console.log('Successfully saved state to database')
        toast.success('🦄 Successfully saved state to database!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const updateUserInfo = (e,section,name=e.target.name,value=e.target.value) => {
        e.preventDefault();
        if(section === 'basicInfo' || section === 'currentEducation'){
            setUserInfo( prevState => {
                const newUserInfo = {...prevState};
                newUserInfo[section][name] = value;
                return newUserInfo;
            });
        }else if(section === 'testScore' || section === 'awards' || section === 'activities'){
            setUserInfo( prevState => {
                return {...prevState, [name]: value}
            });
        }
        console.log(userInfo);
    }

    const handleProfileComponentIdOnChange = (pathName) => {
        const id = navLinks.filter((link) => link.path === `/profile/${pathName}`)[0].id;
        console.log(id);
        setCurrentProfileComponentId(id);
    }

    const updateComponentIdByParams = (singlePartId) => {
        if(singlePartId){
            const id = navLinks.filter((link) => link.path === `/profile/${singlePartId}`)[0].id;
            console.log(id);
            setCurrentProfileComponentId(id);
        }
    }

    // placeholder for profile matching
    async function getProfileMatching() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve([format,format2])
            }, 1000);
        });
    }

    return (
        <ProfileContext.Provider
            value={{userInfo,
                setUserInfo,
                setLocalStorage,
                getLocalStorage,
                navLinks,
                nextProfileComponentPath,
                previousProfileComponentPath,
                updateUserInfo,
                currentProfileComponentId,
                handleProfileComponentIdOnChange,
                isRequiredFilled,
                setIsRequiredFilled,
                updateComponentIdByParams,
                showSummary,
                setShowSummary,
                showProfileMatching,
                setShowProfileMatching,
                getProfileMatching,
                getUserInfoFromCloud,
                saveUserInfoToCloud,
            }}>
            {children}
            <ToastContainer />
        </ProfileContext.Provider>
    );
}


export const useProfileContext = () => useContext(ProfileContext);
export default ProfileProvider;