import React, { useState } from 'react';
import useDoc from "../context/docContext";
import {useNavigate} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DocAdd() {
    const { docsState, docsDispatch, DOC_ACTION_TYPE, getNewDocId } = useDoc();
    const navigate = useNavigate();
    const [docData, setDocData] = useState(
        { docName: '', type: '', school: '', content: '' });

    function successOnAddDoc() {
        console.log("Doc added");
        toast("A new document has been added!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });}

    function handleInputChange(e) {
        const { id, value } = e.target;
        setDocData(prevData => ({
            ...prevData,
            [id]: value,
        }));
    }

    function handleFormSubmit(e) {
        e.preventDefault();
        const newDocId = getNewDocId();
        const { docName, type, school, content } = docData;
        const newDoc = {
            id: newDocId,
            name: docName,
            school: school,
            type: type,
            variations: [
                {
                    id: `${getNewDocId()}-0`,
                    underDocId: newDocId,
                    version: 1,
                    content: content,
                    suggestions: "",
                }]
        };
        console.log('newdoc: ', newDoc)
        docsDispatch({
            type: DOC_ACTION_TYPE.ADD_DOC,
            payload: newDoc,
        });
        successOnAddDoc();
        navigate("/docs");
    }

    return (
        <div className="pt-[120px] px-12">
            {/* back btn */}
            <button onClick={() => {navigate("/docs")}}>Back to Doc Dashboard</button>
            <h1>Add a new document</h1>
            <p>This is the page where you can add a new document.</p>
            <div>
                <span>Id: </span>{getNewDocId()}
            </div>
            <form onChange={handleInputChange} onSubmit={handleFormSubmit}>
                <label htmlFor="docName">Name</label>
                <input type="text" id="docName" value={docData.docName} />

                <label htmlFor="type">Type</label>
                <input type="text" id="type" value={docData.type} />

                <label htmlFor="school">School</label>
                <input type="text" id="school" value={docData.school} />

                <div>
                    <label htmlFor="content">Content</label>
                    <textarea id="content" value={docData.content} />
                </div>

                <button type="submit">Add Doc</button>
            </form>
        </div>
    );
}
