import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/NavbarComponents/Navbar";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Pricing from './pages/Pricing';

import {AppProvider, UserProvider} from "./context";
import ProfileSharedLayout from "./pages/ProfileSharedLayout";
import SingleProfilePart from "./pages/SingleProfilePart";
import AppFooter from "./components/AppFooter";
import {DocProvider} from "./context/docContext";
import DocDashboard from "./pages/DocDashboard";
import DocVariation from "./pages/DocVariation";
import DocAdd from "./pages/DocAdd";
import DocEdit from "./pages/DocEdit";
import {DocFilterProvider} from "./context/docFilterContext";

function App() {

  return (
      <BrowserRouter>
          <AppProvider>
            <UserProvider>
                <DocProvider>
                    <DocFilterProvider>
                      <Navbar />
                      <Routes>
                        <Route path="/" element={<Home/>} />
                        <Route path="/login" element={<Login/>} />
                        <Route path="/profile" element={<ProfileSharedLayout/>} >
                            <Route index element={<Profile/>} />
                            <Route path=":singlePartId" element={<SingleProfilePart/>} />
                        </Route>
                        <Route path="/pricing" element={<Pricing/>} />
                        <Route path="/docs">
                            <Route index element={<DocDashboard />} />
                            <Route path="add" element={<DocAdd />} />
                            <Route path="edit/:docId" element={<DocEdit />} />
                            <Route path=":docVariationId" element={<DocVariation />} />
                        </Route>
                      </Routes>
                      <AppFooter />
                    </DocFilterProvider>
                </DocProvider>
            </UserProvider>
          </AppProvider>
    </BrowserRouter>
  );
}

export default App;
