const format2 = {
    "url": "https://www.baidu.com",
    "nationality": "中国",
    "gender": "Female",
    "lgbtq": "nan",
    "school": "1",
    "schoolName": "nan",
    "system": "5",
    "schoolLocation": "1",
    "gpa": "4.07/4.3",
    "major": "nan",
    "overall":"小A是一个全面发展、对社会问题有深刻见解的申请者。她的标化考试成绩出色，AP和SAT II成绩也相当优秀。在活动方面，小A展示了她在社会科学和自然科学方面的多才多艺，也有丰富的体育和艺术活动。尽管奖项不多，但小A通过真实、一致的申请文书展现了自己的个性和价值观，这也可能是Rice University录取她的重要原因。 小A对自我认知有深刻的理解，这在申请过程中表现得尤为明显。她不仅被自己心仪的学校录取，还因此有更多的时间和精力去探索和认识自己。",
    "grade": [
        {
            "type": "1",
            "total": 5,
            "detail": [
                {
                    "name": "Physics",
                    "grade": 5
                }
            ]
        }],
    "testScore": [
        {
            "testType": "TOEFL",
            "testScore": "112",
            "listening": "30",
            "reading": "28",
            "writing": "26",
            "speaking": "28",
        },{
            "testType": "SAT",
            "testScore": "1550",
            "reading": "710",
            "math": "790",
        }],
    "result": [
        {
            "index": 1,
            "school": "Rice University (Class of 2023)",
            "stage": 1
        },
        {
            "index": 2,
            "school": "EA&RD:UIUC",
            "stage": 1
        }
    ],
    "activity": [
        {
            "index": 1,
            "detail": "Pioneer课题：小A进行了一个关于Civil Rights Movement的研究课题，尽管没有教授推荐信，但这体现了她对社会问题的深刻洞见。",
            "feedback": "nan",
            "type": "1c",
            "grade": "nan"
        },
        {
            "index": 2,
            "detail": "Gentrification研究：在学习人,文地理后，小A对gentrification产生浓厚兴趣。她为北京的胡同进行了实地考察，并写了一个中英双语的20页报告。",
            "feedback": "nan",
            "type": "1c",
            "grade": "nan"
        },
        {
            "index": 3,
            "detail": "足球队、滑雪、画画：小A还参与了多项体育和艺术活动，显示了她全面发展的特点。",
            "feedback": "nan",
            "type": "1c",
            "grade": "nan"
        }
    ],
    "awards": [
        {
            "awardName": "英国皇家化学挑战赛",
            "awardGrade": "铜奖",
            "awardType": "",
            "awardSubject": "",
            "awardLevel": 4
        },
        {
            "awardName": "USAD中国赛Literature",
            "awardGrade": "铜牌",
            "awardType": "",
            "awardSubject": "",
            "awardLevel": 4
        }
    ],
}

export default format2;
