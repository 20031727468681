import React from "react";
import useDocFilter from "../../context/docFilterContext";

export default function Filters(){
    const {filtered_docs, all_docs, updateFilters, updateSort} = useDocFilter();

    const locationOptions = ["all", ...new Set(all_docs.map((doc) => doc.location))];
    const typeOptions = ["all", ...new Set(all_docs.map((doc) => doc.type))];
    const schoolOptions = ["all", ...new Set(all_docs.map((doc) => doc.school))];
    const sortOptions = ["name-a", "name-z", ""];

    return(
        <main className={"w-full flex flex-row justify-between items-center"}>
            <section className={"flex flex-row items-center gap-0"}>
                <div className={"flex flex-row items-center gap-3"}>
                    <label className={"m-0 p-0 w-auto"} htmlFor="location">Location</label>
                    <select
                        className={"rounded-full"}
                        name="location"
                        id="location"
                        onChange={(e) => {updateFilters(e)}} >
                        {locationOptions.map((option, index) => {
                            return <option value={option} key={`location-${index}`}>{option}</option>
                        })}
                    </select>
                </div>
                <div className={"flex flex-row items-center gap-3"}>
                    <label className={"m-0 p-0 w-auto"} htmlFor="type">Type</label>
                    <select
                        className={"rounded-full"}
                        name="type"
                        id="type"
                        onChange={(e) => {updateFilters(e)}} >
                        {typeOptions.map((option, index) => {
                            return <option value={option} key={`type-${index}`}>{option}</option>
                        })}
                    </select>
                </div>
                <div className={"flex flex-row items-center gap-3"}>
                    <label className={"m-0 p-0 w-auto"} htmlFor="school">School</label>
                    <select
                        className={"rounded-full"}
                        name="school"
                        id="school"
                        onChange={(e) => {updateFilters(e)}} >
                        {schoolOptions.map((option, index) => {
                            return <option value={option} key={`school-${index}`}>{option}</option>
                        })}
                    </select>
                </div>
            </section>
            {/* sort */}
            <section className={"flex flex-row items-center gap-0"}>
                <div className={"flex flex-row items-center gap-3"}>
                    <label className={"m-0 p-0 w-auto"} htmlFor="sort">Sort</label>
                    <select
                        className={"rounded-full"}
                        name="sort"
                        id="sort"
                        onChange={(e) => {updateSort(e)}} >
                        {sortOptions.map((option, index) => {
                            return <option value={option} key={`sort-${index}`}>{option}</option>
                        })}
                    </select>
                </div>
                <input
                    className={"rounded-full w-auto"}
                    type="text"
                    name="text"
                    id="search"
                    onChange={(e) => {updateFilters(e)}}
                    placeholder="Search" />
            </section>
        </main>
    )
}