export const sample2_gtech_essay = "Georgia Tech Essay\n" +
    "\n" +
    "**Why do you want to study your chosen major specifically at Georgia Tech?**\n" +
    "\n" +
    "G-Tech is my heaven to learn, explore, and contribute, to realize my wildest dreams.\n" +
    "\n" +
    "My love of engineering stemmed from the passion to innovate from doing hands-on work. Discovering that back-EMF would damage the stepper motors used in balance cars, I spent 2 months designing a protective module. Icooperated with one of my classmates. Using the knowledge of diode and triode, we defined critical parametersα, beta, and U, and modeled their relationships with various resistors through iCircuit simulation. With a 40-page paper, the simulation results justified our arrangement of electronic units! For me, engineering is the key to\n" +
    "turning elusive, creative, and even crazy ideas into reality.\n" +
    "\n" +
    "As I read through the papers offered in the 47th Annual IEEE International Symposium on Micro-architecture, I was amazed by G-Tech’s scholars who investigated the emissions from computers and developed a metric for measuring side-channel signals to better the cybersecurity for phones and laptops. I was driven by the mission of defending cyber-security as well. During my internship as a technician at ZCRK, a Chinese intelligent car controlcompany, I created a cyber-attack detection system. I designed an algorithm to self-conduct statistical tests on the\n" +
    "spectrum input data and set a threshold for alarm. The algorithm would help protect an automatic vehicle in its Over-The-Air software updating process.\n" +
    "\n" +
    "Aiming to be an industry innovator, I look forward to ECE2002 ECE Seminar to reach out to various professionals with diverse backgrounds to learn from their experiences on entrepreneurial ventures. When I visited Tech Squareat the east of the campus, I was amazed by the dazzling innovation centers of tech companies such as AT&T, Anthem, and Coco-Cola. As a team-builder, critical-thinker, and dream-holder, I aspire to improve upon electrical power technology to reshape our 21-century under G-Tech’s motto, “Progress and Service”."

export const sample2_gtech_essay_suggestions = "### Perspective 1: Harvard Admissions Officer Perspective\n" +
    "\n" +
    "### Strengths\n" +
    "\n" +
    "1. Line 7: Be Authentic - The details about your love for engineering, especially hands-on work and real-world applications, bring authenticity to your essay. [Score: 8/10]\n" +
    "2. Line 17: Show Don't Tell - The essay does a good job showing the applicant's passion for cybersecurity and engineering through specific projects and experiences. [Score: 9/10]\n" +
    "3. Line 21: Focus on Deeper Themes - The mention of G-Tech’s motto, \"Progress and Service,\" suggests a deeper understanding of the university’s ethos. [Score: 7/10]\n" +
    "\n" +
    "### Weaknesses\n" +
    "\n" +
    "1. Line 1: Grab the Reader from the Start - The first line \"G-Tech is my heaven\" sounds overly dramatic and may not engage all readers. [Score: 4/10]\n" +
    "2. Line 4-14: Write with the Reader in Mind - The text contains numerous technical details that may not be understandable to someone not versed in engineering. [Score: 5/10]\n" +
    "3. Line 21: End Your Essay with a \"Kicker\" - The essay ends with the motto but doesn't tie back to your personal experience or offer a memorable takeaway. [Score: 5/10]\n" +
    "\n" +
    "**Average Score: 6.3/10**\n" +
    "\n" +
    "**General Comment**: The essay is strong in showing your passion and skills in engineering but lacks a compelling introduction and conclusion. The highly technical language might also alienate non-specialist readers."