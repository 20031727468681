export const docFilterInitialState = {
    filtered_docs: [],
    all_docs: [],
    grid_view: true,
    sort: 'name-a',
    filters: {
        text: '',
        school: 'all',
        location: 'all',
        type: 'all',
    }
}

export const DOC_FILTER_ACTION_TYPE = {
    LOAD_DOCS: 'LOAD_DOCS',
    FILTER_DOCS: 'FILTER_DOCS',
    UPDATE_SORT: 'UPDATE_SORT',
    SORT_DOCS: 'SORT_DOCS',
    UPDATE_FILTERS: 'UPDATE_FILTERS',
    CLEAR_FILTERS: 'CLEAR_FILTERS',
}

export const docFilterReducer = (state, action) => {
    switch(action.type){
        case DOC_FILTER_ACTION_TYPE.LOAD_DOCS:
            const newDocs = [...action.payload]
            // payload is an array of docs
            return {
                ...state,
                all_docs: newDocs,
                filtered_docs: newDocs,
                filters: {
                    ...state.filters,
                    school: 'all',
                    location: 'all',
                    type: 'all',
                    text: '',
                }
            }
        case DOC_FILTER_ACTION_TYPE.UPDATE_SORT:
            // payload: e.target.value.
            return { ... state, sort: action.payload }
        case DOC_FILTER_ACTION_TYPE.SORT_DOCS:
            const { sort, filtered_docs } = state
            let tempSortedDocs = []
            if(sort === 'name-a'){
                tempSortedDocs = filtered_docs.sort((a, b) => {
                    return a.name.localeCompare(b.name)
                })
            }
            if(sort === 'name-z'){
                tempSortedDocs = filtered_docs.sort((a, b) => {
                    return b.name.localeCompare(a.name)
                })
            }
            if(sort === ''){
                tempSortedDocs = filtered_docs
            }
            return { ...state, filtered_docs: tempSortedDocs }
        case DOC_FILTER_ACTION_TYPE.UPDATE_FILTERS:
            const { name, value } = action.payload
            return { ...state, filters: { ...state.filters, [name]: value } }
        case DOC_FILTER_ACTION_TYPE.FILTER_DOCS:
            const { all_docs } = state
            const { text, school, location, type } = state.filters
            const tempFilteredDocs = [...all_docs]
                .filter(doc => doc.name.toLowerCase().startsWith(text) || text === '')
                .filter(doc => doc.school === school || school === 'all')
                .filter(doc => doc.type === type || type === 'all')
                .filter(doc => doc.location === location || location === 'all')
            return { ...state, filtered_docs: tempFilteredDocs }
        case DOC_FILTER_ACTION_TYPE.CLEAR_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    text: '',
                    school: 'all',
                    location: 'all',
                    type: 'all',
                }
            }
        default:
            throw new Error(`No Matching "${action.type}" - action type`)
    }
}

export default docFilterReducer;