/* eslint-disable no-unused-vars */
import React, {useContext} from "react";
import { Link } from "react-router-dom";
import WeLightLogo from "../../assets/WeLightLogo.svg";
import { useState } from "react";
import {AppContext, useApp, LANG} from "../../context/appContext";
import { motion } from "framer-motion"
import {useUser} from "../../context/userContext";

const transitionStyles = "transition-all ease-in-out duration-300";
const planText = {
    [LANG.EN]: "Plan",
    [LANG.CN]: "套餐计划",
}
const loginText = {
    [LANG.EN]: "Login",
    [LANG.CN]: "登录",
}
const accountText = {
    [LANG.EN]: "Account",
    [LANG.CN]: "账户",
}

export default function Navbar() {
  const {lang, setLang} = useApp();
  const {user, logout} = useUser();
  const [showLogout, setShowLogout] = useState(false);

  function handleLogout() {
    logout();
    setShowLogout(false)
  }

  return (
    <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="fixed top-0 left-0 w-full z-10 bg-transparent flex-row justify-between">
      <div className="bg-transparent max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <img src={WeLightLogo} alt="WeLight Logo" className="w-15 pr-4" />
            <span className="self-center text-xl font-bold text-white">WeLight</span>
          </Link>
        </div>
        <div
          className="w-full md:block md:w-auto space-x-8"
          id="navbar-default"
        >
          <ul className="flex flex-row items-center text-sky-600 gap-1">
              <li>
                  <select
                  className={`btn btn-ghost normal-case mx-1 px-1 text-lg rounded hover:bg-sky-300 ${transitionStyles}}`}
                  onChange={(e) => {
                      e.preventDefault();
                      setLang(e.currentTarget.value);
                  }}>
                  <option value={LANG.EN}>English</option>
                  <option value={LANG.CN}>中文</option>
              </select></li>
              {user &&
                  <li>
                      <Link
                        to="/profile"
                        className={`btn btn-ghost normal-case px-1 text-lg rounded hover:bg-sky-300 ${transitionStyles}}`}
                      >
                          Profile
                      </Link>
                  </li>
              }
              {user &&
                  <li>
                      <Link
                          to="/docs"
                          className={`btn btn-ghost normal-case px-1 text-lg rounded hover:bg-sky-300 ${transitionStyles}}`}
                      >
                          Documents
                      </Link>
                  </li>
              }
              <li>
                <Link
                  to="/pricing"
                  className={`btn btn-ghost normal-case px-1 text-lg rounded hover:bg-sky-300 ${transitionStyles}}`}
                >
                {planText[lang]}
                </Link>
              </li>
            {user ? (
              <li className={"relative"}>
                  <span>Welcome, {user.username}.</span>
                  <span><button onClick={()=>setShowLogout(prev => !prev)}>{showLogout? 1 : 0}</button></span>
                  {showLogout && <button className={"absolute top-6 right-0"} onClick={handleLogout}>Logout</button>}
              </li>
            ) : (
                <li>
              <Link
                to="/login"
                className={`btn btn-ghost normal-case px-1 text-lg rounded hover:bg-sky-300 ${transitionStyles}}`}
              >
                {loginText[lang]}
              </Link></li>
            )}
          </ul>
        </div>
      </div>
    </motion.div>
  );
}
