import {docs as dummyDocs} from "../constants/doc";

const docsInitialState = [];
function getDocsDummy() {
    setTimeout(() => {
        return dummyDocs;
    }, 1000);
}

// docs:[] <- doc {} <- variations [] <- variation {}
export const DOC_ACTION_TYPE = {
    GET_DOCS: 'GET_DOCS',
    ADD_DOC: 'ADD_DOC',
    DELETE_DOC: 'DELETE_DOC',
    UPDATE_DOC_PROPERTY: 'UPDATE_DOC_PROPERTY', // edit doc name, school, location, type
    ADD_VARIATION: 'ADD_VARIATION',
    DELETE_VARIATION: 'DELETE_VARIATION',
    UPDATE_VARIATION: 'UPDATE_VARIATION', // edit variation content, suggestions...
}
export  const docsReducer = (state, action) => {
    switch (action.type) {
        case DOC_ACTION_TYPE.GET_DOCS:
            return dummyDocs;
        case DOC_ACTION_TYPE.ADD_DOC:
            // payload need to be a doc object
            return [...state, action.payload];
        case DOC_ACTION_TYPE.DELETE_DOC:
            // payload need to be a doc id
            return state.filter(doc => doc.id !== action.payload);
        case DOC_ACTION_TYPE.UPDATE_DOC_PROPERTY:
            // payload need to be a doc object, which has an id
            return state.map(doc => {
                if(doc.id === action.payload.id){
                    return action.payload;
                }
                return doc;
            })
        case DOC_ACTION_TYPE.ADD_VARIATION:
            // payload need to be a variation object, which has an underDoc id
            return state.map(doc => {
                if(doc.id === action.payload.underDocId){
                    return {
                        ...doc,
                        variations: [...doc.variations, action.payload.newVariation]
                    }
                }
                return doc;
            })
        case DOC_ACTION_TYPE.DELETE_VARIATION:
            // payload need to be a variation object, which has a underDoc id and an id
            return state.map(doc => {
                if(doc.id === action.payload.underDocId){
                    return {
                        ...doc,
                        variations: doc.variations.filter(variation => variation.id !== action.payload.id)
                    }
                }
                return doc;
            })
        case DOC_ACTION_TYPE.UPDATE_VARIATION:
            // payload {underDocId, variationId, content}
            return state.map(doc => {
                // find the doc
                if(doc.id === action.payload.underDocId){
                    return {
                        ...doc,
                        variations: doc.variations.map(variation => {
                            // find the variation
                            if(variation.id === action.payload.variationId){
                                return {
                                    ...variation,
                                    content: action.payload.content
                                }
                            }
                            return variation;
                        })
                    }
                }
                return doc;
            })
        default:
            return state;
    }
}

export default docsReducer;