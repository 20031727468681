import React, {useState, createContext, useContext} from 'react';
import {useNavigate} from "react-router-dom";

const userContext = createContext({});
export const WELIGHT_USER_TOKEN_STORAGE_NAME = 'welight-user-token'

function UserProvider({children}) {
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [err, setErr] = useState(null);
    const [isLogining, setIsLogining] = useState(false);

    const navigate = useNavigate();

    async function login(username, password) {
        setIsLogining(true);
        setErr(null)
        const options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                username: username,
                password: password
            }),
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/login`, options);

        if (!response.ok) {
            // network failure
            console.error('Network response was not ok');
            setIsLogining(false)
            return;
        }

        const data = await response.json();
        // data example:
        // {
        //     "code": 0,
        //     "data": {
        //     "accessToken": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY5NDQ2NTU0MywianRpIjoiZGFkMjAyOWEtZjg1ZC00M2NhLWI5ZTEtNGE0YzE1YmVhNjRmIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6eyJpZCI6MSwidXNlcm5hbWUiOiJhbm5odW55IiwiZW1haWwiOiJhQGEuY29tIiwiY3JlYXRlZF9hdCI6IkZyaSwgMjUgQXVnIDIwMjMgMjA6MTA6MTggR01UIiwidXBkYXRlZF9hdCI6IkZyaSwgMjUgQXVnIDIwMjMgMjA6MTA6MTggR01UIn0sIm5iZiI6MTY5NDQ2NTU0MywiZXhwIjo0ODE2NTI5NTQzfQ.cNiEP62Lgac33L3bOv2CbZk5ar4wMgr3RPF1qnQUwrI",
        //         "user": {
        //         "created_at": "Fri, 25 Aug 2023 20:10:18 GMT",
        //             "email": "a@a.com",
        //             "id": 1,
        //             "updated_at": "Fri, 25 Aug 2023 20:10:18 GMT",
        //             "username": "annhuny"
        //     }
        // },
        //     "errMsg": null,
        //     "success": true
        // }
        if (!data.success) {
            // login failed
            setErr("Please check your username and password, then try again");
            console.error("login failed, error: ", data.error);
            setIsLogining(false)
            return;
        }

        // login success! :)
        const {accessToken, user} = data.data;
        console.log(data);
        setUser(user);  // Assuming the response contains user data
        // user example:
        // {
        //     "created_at": "Fri, 25 Aug 2023 20:10:18 GMT",
        //         "email": "a@a.com",
        //         "id": 1,
        //         "updated_at": "Fri, 25 Aug 2023 20:10:18 GMT",
        //         "username": "annhuny"
        // }
        setToken(accessToken);  // Assuming the response contains a token
        localStorage.setItem(WELIGHT_USER_TOKEN_STORAGE_NAME, accessToken);
        navigate("/profile");
        setIsLogining(false)
    }

    function logout() {
        setUser(null);
        setToken(null);
        setErr(null);
        localStorage.removeItem(WELIGHT_USER_TOKEN_STORAGE_NAME);
        navigate("/");
    }

    async function register(username, email, password){
        setErr(null)
        const options = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                username: username,
                email: email,
                password: password,
            }),
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/register`, options);

        if (!response.ok) {
            // network failure
            console.error('Network response was not ok');
            return;
        }

        const data = await response.json();
        const {success, code,errMsg} = data;

        if (!success) {
            // register failed
            console.error("login failed, error: ", errMsg);
            if(errMsg === "Email already exists" || code === 203) {
                setErr("Email already exists");
                alert("Email already exists");
                return;
            }else if(errMsg === "Username already exists" || code === 202){
                setErr("Username already exists");
                alert("Username already exists");
                return;
            }
            return;
        }

        // register success! :)
        const {accessToken, user} = data.data;
        alert("Register success! :)");
        setUser(user);  // Assuming the response contains user data
        setToken(accessToken);
        localStorage.setItem(WELIGHT_USER_TOKEN_STORAGE_NAME, accessToken);
        navigate("/profile");
    }

    return(
        <userContext.Provider value={{
            login,
            logout,
            register,
            isLogining,
            user,
            token,
            err,
        }}>
            {children}
        </userContext.Provider>
    )
}

export function useUser() {
    return useContext(userContext);
}

export default UserProvider;