import React from 'react';
import { createContext,useContext} from "react";
import docReducer, {DOC_ACTION_TYPE} from "../reducer/docReducer";
import {docs as dummyDocs} from "../constants/doc";

const DocContext = createContext({});
const localDocs = dummyDocs || JSON.parse(localStorage.getItem('welight-docs')) || [];

// docs:[] <- doc {} <- variations [] <- variation {}
export const DocProvider = ({children}) => {
    const [docsState, docsDispatch] = React.useReducer(docReducer, localDocs);

    // get a new doc id. e.g. [doc-1, doc-2, doc-3] -> 'doc-4'
    function getNewDocId(){
        const newIdNum = docsState.map(doc => doc.id.split('-')[1]).map(id => parseInt(id)).reduce((a,b) => a>b?a:b, 0) + 1;
        return `doc-${newIdNum < 10 ? '0'+newIdNum : newIdNum}`;
    }

    function getNewVariationId(underDocId){
        const doc = docsState.find(doc => doc.id === underDocId);
        const newVersionIndex = doc.variations.map(variation => variation.version).reduce((a,b) => a>b?a:b, 0) + 1;
        return `${underDocId}-${newVersionIndex}`;
    }

    function getNewVariationVersion(underDocId){
        const doc = docsState.find(doc => doc.id === underDocId);
        return doc.variations.map(variation => variation.version).reduce((a,b) => a>b?a:b, 0) + 1;
    }

    function saveDocsToCloud(){
        // TO DO:
        localStorage.setItem('welight-docs', JSON.stringify(docsState));
    }

    return (
        <DocContext.Provider value={{
            docsState,
            docsDispatch,
            DOC_ACTION_TYPE,
            getNewDocId,
            getNewVariationId,
            getNewVariationVersion,
            saveDocsToCloud
        }}>
            {children}
        </DocContext.Provider>
    )
}

export const useDoc = () => useContext(DocContext);

export default useDoc;