import React, {useContext, useEffect, useReducer} from 'react'
import useDoc from "./docContext";
import {DOC_FILTER_ACTION_TYPE, docFilterInitialState, docFilterReducer} from "../reducer/docFilterReducer";

const DocFilterContext = React.createContext()

export function DocFilterProvider({children}) {
    const {docsState} = useDoc();
    const [filteredDocsState, filteredDocsDispatch] = useReducer(docFilterReducer, docFilterInitialState)

    useEffect(() => {
        filteredDocsDispatch({type: DOC_FILTER_ACTION_TYPE.LOAD_DOCS, payload: docsState})
    }, [docsState])

    // when sort change, sort docs
    useEffect(() => {
        filteredDocsDispatch({type: DOC_FILTER_ACTION_TYPE.SORT_DOCS})
    }, [filteredDocsState.sort])

    // when filters change, filter docs
    useEffect(() => {
        filteredDocsDispatch({type: DOC_FILTER_ACTION_TYPE.FILTER_DOCS})
    }, [filteredDocsState.filters])

    function updateSort(e) {
        filteredDocsDispatch({type: DOC_FILTER_ACTION_TYPE.UPDATE_SORT, payload: e.target.value})
    }

    function updateFilters(e) {
        const {name, value} = e.target
        filteredDocsDispatch({type: DOC_FILTER_ACTION_TYPE.UPDATE_FILTERS, payload: {name, value}})
    }

    function clearFilters() {
        filteredDocsDispatch({type: DOC_FILTER_ACTION_TYPE.CLEAR_FILTERS})
    }

    return (
        <DocFilterContext.Provider value={{
            ...filteredDocsState,
            updateSort,
            updateFilters,
            clearFilters,
        }}>
            {children}
        </DocFilterContext.Provider>
    )
}

export default function useDocFilter() {
    return useContext(DocFilterContext)
}