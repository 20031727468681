import {useState} from "react";
import Avatar, { genConfig } from 'react-nice-avatar';
import {faker} from '@faker-js/faker';
import {FaArrowRight} from "react-icons/fa";

const avatarConfigs = Array.from({ length: 10 }, () => genConfig());
const fakeNames = Array.from({ length: 10 }, () => faker.person.lastName());

export default function ProfileMatchingCard({profileMatchings}) {

    return (<div className={"p-3"}>
        <h1>Profile Matching</h1>
        <div className={"grid grid-cols-3 gap-4 mt-3"}>
            {profileMatchings.map((profile, index) => {
                const {result, activity} = profile;
                const schools = result.map(res=>res.school);
                const activityDetails = activity.map(act=>act.detail);
                const [showDetail, setShowDetail] = useState(false);

                const {nationality, schoolName:currentSchoolName, gpa, major, grade:grades, testScore, overall, awards} = profile;

                const randomName = fakeNames[index % fakeNames.length]; // generate random name
                const myConfig =  avatarConfigs[index % avatarConfigs.length]; // generate random avatar

                return (
                    <div key={index}
                         className={"relative px-3 pt-5 pb-12 border border-sky-100 rounded-lg shadow-sm"}>
                        {/*<p className={"text-xs absolute bottom-0 left-0 text-gray-50"}>Case {index + 1}</p>*/}
                        <div className={'flex flex-row'}>
                            <div className={'p-3 flex items-center'} title={randomName}>
                                {/* avatar */}
                                <Avatar className="w-24 h-24" {...myConfig} />
                            </div>
                            <div className={'px-3 flex-1'}>
                                {/* info */}
                                <h1>{randomName}</h1>
                                <hr className={"my-1 w-full"}/>
                                <p className={'text-xs mt-3 mb-1'}>Admitted to:</p>
                                <div className={"flex flex-col gap-0"}>
                                    {schools.map((school, index) => {
                                        return (
                                            <span
                                                key={index}
                                                title={school}
                                                className={"font-thin leading-tight capitalize"}
                                            >
                                                - {school.length > 25 ? school.substring(0,25) + "..." : school} &nbsp;
                                            </span>
                                        )})}
                                </div>
                            </div>
                        </div>
                        <div className={'absolute right-1 top-3 transform rotate-45'}>
                            {/* year */}
                            2023
                        </div>
                        <div>
                            {/*  basic intro */}
                            <div className={'mt-3 text-sm px-3'}>
                                {activityDetails.map((detail, index) => {
                                    return (
                                        <span key={index}>{detail.substring(0,25) + "..."} &nbsp;</span>
                                    )})}
                            </div>
                        </div>

                        <button
                            type={"button"}
                            className={"text-xs font-thin absolute bottom-2 right-3 flex flex-row"}
                            onClick={()=> setShowDetail(true)}>👉 Details</button>
                        {/* detailed info */}
                        {showDetail &&
                            <div className={"fixed w-screen h-screen top-0 left-0 z-10"}>
                                <div className={"w-full h-full flex justify-center items-center bg-black bg-opacity-50 text-center"}>
                                    <div className={"bg-white px-6 py-3 flex flex-row justify-center gap-3 rounded-lg relative"}>
                                        <div className={'p-3 flex items-center'} title={randomName}>
                                            {/* avatar */}
                                            <Avatar className="w-24 h-24" {...myConfig} />
                                        </div>
                                        <div>
                                            <div className={'flex flex-col items-start text-left gap-1'}>
                                                {/* info */}
                                                <h1>{randomName}</h1>
                                                <hr className={"my-1 w-full"}/>
                                                <p className={'text-xs mt-3 mb-1'}>Admitted to:</p>
                                                <div className={"flex flex-col gap-0"}>
                                                    {schools.map((school, index) => {
                                                        return (
                                                            <span
                                                                key={index}
                                                                title={school}
                                                                className={"font-thin leading-tight capitalize"}
                                                            >
                                                                - {school} &nbsp;
                                                            </span>
                                                        )})}
                                                </div>
                                                <span>- from {nationality}, once studied at {currentSchoolName} with GPA {gpa} in {major} major</span>
                                                {/* testScore */}
                                                {testScore.map((score, index) => {
                                                    return (
                                                        <div>
                                                            <span key={index}>- {score?.testType}: {score?.testScore} &nbsp;</span>
                                                            {score?.listening && <span>Listening: {score?.listening} &nbsp;</span>}
                                                            {score?.reading && <span>Reading: {score?.reading} &nbsp;</span>}
                                                            {score?.writing && <span>Writing: {score?.writing} &nbsp;</span>}
                                                            {score?.speaking && <span>Speaking: {score?.speaking} &nbsp;</span>}
                                                            {score?.math && <span>Math: {score?.math} &nbsp;</span>}
                                                        </div>
                                                    )})
                                                }
                                                {/* awards */}
                                                <div className={'max-w-4xl'}>
                                                    <p>Awards:</p>
                                                    {awards.map((award, index) => {
                                                        return (
                                                            <div>- <span key={index}>{award?.awardName}: {award?.awardGrade} </span></div>
                                                        )})}
                                                </div>
                                                {/* activities */}
                                                <div className={'max-w-4xl'}>
                                                    <p>Activities:</p>
                                                    {activityDetails.map((detail, index) => {
                                                    return (
                                                        <div>- <span key={index}>{detail} </span></div>
                                                    )})}
                                                </div>
                                                {/*    overall */}
                                                <span className={'max-w-4xl'}>
                                                    <span>Overall: </span>
                                                    <span>{overall} </span>
                                                </span>
                                            </div>
                                            <button
                                                type={"button"}
                                                className={"text-sm font-thin absolute top-3 right-3"}
                                                onClick={()=> setShowDetail(false)}>Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                )
            })}
        </div>
    </div>)
}