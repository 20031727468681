import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import useDoc from "../context/docContext";
import {DOC_ACTION_TYPE} from "../reducer/docReducer";
import {motion} from "framer-motion";

function BackToDocsDashboardBtn() {
    const navigate = useNavigate();
    return (
        <button
            className={"font-thin text-sm text-gray-500 hover:text-gray-700 cursor-pointer"}
            onClick={() => {navigate("/docs")}}
        >
            &lt; View all
        </button>);
}

export default function DocVariation() {
    const {docsState, docsDispatch, DOC_ACTION_TYPE, getNewVariationId, getNewVariationVersion, saveDocsToCloud} = useDoc();

    const {docVariationId} = useParams();// 'doc-00-0'

    const underDocId = `doc-${docVariationId.split("-")[1]}`; // "doc-00"
    const underDoc = docsState.find(doc => doc.id === underDocId);

    // Check if underDoc is not defined
    if (!underDoc) {
        return <div>Error: Document {docVariationId} not found</div>;
    }else{
        console.log(underDoc)
    }

    // Check if underDoc.variations is an array before using .find()
    const variation = underDoc.variations
        .find((variation) => variation.id === docVariationId)
    console.log(variation)
    const navigate = useNavigate();

    const [content, setContent] = useState(variation.content);
    const [isInfoFolded, setIsInfoFolded] = useState(false);

    // save content as soon as user changes it
    function handleContentChange(e){
        const newContent = e.target.value;
        setContent(newContent);

        docsDispatch({
            type: DOC_ACTION_TYPE.UPDATE_VARIATION,
            payload: {
                underDocId: underDocId,
                variationId: docVariationId,
                content: newContent,
            }
        })
    }

    function handleSaveNewVersion(){
        const newVersionIndex = getNewVariationVersion(underDocId);
        const newDocVariationId = getNewVariationId(underDocId);

        docsDispatch({
            type: DOC_ACTION_TYPE.ADD_VARIATION,
            payload: {
                underDocId: underDocId,
                newVariation:{
                    id: newDocVariationId,
                    underDocId: underDocId,
                    version: newVersionIndex,
                    content: content,
                    suggestions: "",
                    isShow: true,
                }
            }
        })
        alert("Saved as new version!")
        navigate(`/docs/${newDocVariationId}`)
    }

    // only when user leaves this page, save docs to cloud.
    useEffect(()=>{
        return () => {
            saveDocsToCloud();
        }
    }, [])

    return (
        <div className={"w-full pt-[120px] px-12 flex flex-col items-start"}>
            <BackToDocsDashboardBtn/>
            <main className={"w-full grid grid-cols-[2fr_1fr] gap-6"}>
                {/*content*/}
                <section>
                    <header className={"flex flex-row justify-between py-3"}>
                        <h1>{underDoc.name}</h1>
                        {/* info */}
                        <div className={"flex flex-row gap-3"}>
                            {!isInfoFolded
                                && <button onClick={()=> setIsInfoFolded(true)}>&gt;</button>}
                            {!isInfoFolded
                                && <motion.ol
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    transition={{duration: 0.5}}
                                    className={"flex flex-row gap-3"}>
                                    <li className={"font-thin"}>School: {underDoc.school}</li>
                                    <li className={"font-thin"}>Type: {underDoc.type}</li>
                                    <li className={"font-thin"}>Version: {variation.version}</li>
                                </motion.ol>
                            }
                            {isInfoFolded
                                && <button onClick={()=> setIsInfoFolded(false)}>&lt;</button>}
                            <span className={"font-bold"}>Info</span>
                        </div>
                    </header>
                    {/* CONTENT */}
                    <textarea
                        className={"w-full min-h-[360px] font-thin p-3 border border-gray-300 rounded-md " +
                            "resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"}
                        onChange={(e)=>handleContentChange(e)}
                        value={content} />
                    {/* save as new version button */}
                    <div className={"mt-6 w-full flex flex-row-reverse items-center gap-3"}>
                        <button onClick={handleSaveNewVersion}>Save as new version</button>
                        <span className={"text-gray-500 text-sm animate-pulse"}>Auto Saving...</span>
                    </div>
                </section>
                {/*suggestions*/}
                <section className={"max-h-max overflow-y-auto"}>
                    <header className={"flex flex-row justify-between py-3"}>
                        <h1>Suggestions</h1>
                    </header>
                    {/*<p>{variation?.suggestions || "No suggestions found! "}</p>*/}
                    {variation?.suggestions.split("\n").map((suggestion, index) => {
                        return <p key={index}>{suggestion}</p>
                    })}
                </section>
            </main>
        </div>
    );
}