import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import useDoc from "../context/docContext";
import {DOC_ACTION_TYPE} from "../reducer/docReducer";
import DocCard from "../components/DocCard";
import useDocFilter from "../context/docFilterContext";
import Filters from "../components/DocsComponents/Filters";

export default function DocDashboard() {
    const {docsState, docsDispatch, DOC_ACTION_TYPE} = useDoc();
    const {filtered_docs, all_docs, updateFilters} = useDocFilter();
    const navigate = useNavigate();

    return (
        <div className={"pt-[120px] px-48"}>
            {/* filter and sort */}
            <Filters />
            {/* display all docs */}
            <div className={"flex flex-wrap gap-12 my-6"}>
                {/* add doc button */}
                <div className={"w-36 border border-gray-200 flex justify-center items-center"}>
                    <button
                        onClick={() => {navigate("/docs/add")}}
                        className={"w-12 h-12 border border-gray-200 rounded-full text-4xl text-gray-200 font-thin"}>+</button>
                </div>
                {/* doc(s) */}
                {filtered_docs.map((doc, index) => {
                    return <DocCard doc={doc} key={`doc-${index}-${doc.id}`}/>
                })}
            </div>
        </div>
    );
}